<template>
  <Animated name="pulse" appear duration="1200">
    <div class="">
      <sequential-entrance fromTop>
        <SellerHeader to="/seller/" headerText="Compartir Micrositio" />
      </sequential-entrance>

      <div class="px-5 py-5">
        <h3 class="text-center my-5 font-weight-bold">{{ eventName }}</h3>

        <div class="">
          <p class="">
            Este evento tiene productos que se comercializan via ecommerce.
            Podes compartir tu link con tu codigo de vendedor a tus asistentes.
          </p>
          <p>
            El monto recaudado en cada venta sera enviada directamente al
            organizador.
          </p>
          <p>
            Si el producto paga comisión, la misma sera asignada a tu cuenta en
            forma directa.
          </p>
          <p>Comparti tu link para empezar a generar ventas online!</p>
        </div>

        <v-row class="mt-5 pt-5">
          <v-col cols="12" class="pt-0 pb-0" @click="goToLink()">
            <v-text-field
              required
              :value="textToCopy"
              color="#00B8D9"
              solo
              flat
              disabled
              background-color="#F4F5F7"
              class="app-input pt-0"
              style="cursor: pointer"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="10">
            <v-btn
              style="width: 100%"
              class="app-btn__gray fluid-btn px-10"
              rounded
              depressed
              large
              color="primary"
              @click="copyToClipBoard"
            >
              <v-icon color="#fff" center> mdi-content-copy </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="productList.length">
          <v-col cols="12">
            <h3 class="text-center my-5 font-weight-bold">
              Productos exclusivos
            </h3>
          </v-col>
          <v-col cols="12">
            <product-list-microsite :products="productList" :canEditProducts="canEditProducts" />
          </v-col>
        </v-row>

        <v-row v-if="promotionalProducts.length">
          <v-col cols="12">
            <h3 class="text-center my-5 font-weight-bold">
              Productos promocionales
            </h3>
          </v-col>
          <v-col cols="12">
            <product-list-microsite :products="promotionalProducts" :sellerCode="sellerCode" :canEditProducts="canEditProducts" />
          </v-col>
        </v-row>
      </div>
    </div>
  </Animated>
</template>

<script>
import { Vue } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import ProductListMicrosite from './ProductListMicrosite.vue'
import axios from '@/services/base.service'

export default {
  data() {
    return {
      eventName: this.$route.params.eventName,
      eventSlug: this.$route.params.eventSlug,
      eventId: this.$route.params.eventId,
      canEditProducts: this.$route.params.canEditProducts,
      sellerCode: this.$route.params.sellerCode,
      textToCopy: '',
      productList: [],
      promotionalProducts: []
    }
  },
  methods: {
    async getProductRestricted() {
      const {
        data: { data },
      } = await axios.get(`/events/${this.eventId}/products-restricted`)

      this.productList = data
    },
    async getPromotionalProducts() {
      const {
        data: { data },
      } = await axios.get(`/events/${this.eventId}/products-promotional`)

      this.promotionalProducts = data
    },
    copyToClipBoard() {
      navigator.clipboard.writeText(this.textToCopy).then(
        function() {
          Vue.$toast.success(`Mensaje copiado!`)
        },
        function(err) {
          Vue.$toast.error(`Error copiando mensaje! Intenta nuevamente`)
        }
      )
    },
    goToLink() {
      window.open(this.textToCopy, '_blank')
    },
  },
  mounted() {
    this.textToCopy = process.env.VUE_APP_DOMAIN_ECOMMERCE + '/event/' + this.eventSlug + '?code=' + this.sellerCode

    this.getProductRestricted()
    this.getPromotionalProducts()
  },
  components: {
    SellerHeader,
    ProductListMicrosite,
  },
}
</script>

<style lang="scss" scope>
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: black !important;
}

.v-icon.v-icon {
  font-size: 18px !important;
}
</style>
